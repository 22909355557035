import { useState, useEffect } from "react";
import {
  Form,
  Button,
  DatePicker,
  Typography,
  Input,
  Checkbox,
  notification,
  Select,
} from "antd";
import dayjs from "dayjs";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError } from "../utils/utilities";
import "./S3TaskVoiceAgent.css";
import Papa from "papaparse";
import masterInventoryItemsCSV from "../assets/s3/Master Inventory Items.csv";

const { Title } = Typography;
const S3TaskVoiceAgent = (props) => {
  const [fileList, setFileList] = useState([]);
  const [waiting] = useState(props.waiting);
  const [enabledSubmit, setEnabledSubmit] = useState(true);
  const [tasks] = useState(props.tasks);
  const [inventoryByType, setInventoryByType] = useState({});
  const [productTypes, setProductTypes] = useState([]);
  const setTasks = props.setTasks;
  const handleCancel = props.handleCancel;
  const setWaiting = props.setWaiting;
  const [form] = Form.useForm();
  const minDOB = dayjs("1900-01-01");
  const maxDOB = dayjs().add(1, "month");
  const minInsuranceDate = dayjs().substract(10, "years");
  const maxInsuranceDate = dayjs().add(10, "years");
  const disableOutOfRangeDates = (current, minDate, maxDate) => {
    return (
      current &&
      (current.isBefore(minDate, "day") || current.isAfter(maxDate, "day"))
    );
  };
  useEffect(() => {
    Papa.parse(masterInventoryItemsCSV, {
      header: true,
      download: true,
      complete: (results) => {
        const processedData = results.data
          .filter((item) => item["Item Name"] && item["Master Inventory ID"])
          .map((item) => ({
            ...item,
            "Display Name": `${item["Item Name"]} (${item["Master Inventory ID"]})`,
          }));

        const groupedData = processedData.reduce((acc, item) => {
          const productType =
            item["Product Type"] === "Mask with Headgear"
              ? "Mask"
              : item["Product Type"];

          if (!acc[productType]) {
            acc[productType] = [];
          }
          acc[productType].push({
            displayName: item["Display Name"],
            productId: item["Master Inventory ID"],
            itemName: item["Item Name"],
            hasHeadgear: item["Product Type"] === "Mask with Headgear",
          });
          return acc;
        }, {});

        setInventoryByType(groupedData);
        const filteredTypes = Object.keys(groupedData).filter(
          (type) => type !== "Machine" && type !== "Misc" && type
        );
        setProductTypes(filteredTypes);

        const defaultValues = {};
        filteredTypes.forEach((type) => {
          const baseKey = `equipment_${type === "Seals" ? "scp" : type.toLowerCase().replace(/ /g, "_")}`;
          const isEligible = s3Values[`${baseKey}_eligible`];

          if (isEligible && groupedData[type]?.length > 0) {
            defaultValues[`${baseKey}_product`] =
              groupedData[type][0].productId;
          }
        });

        form.setFieldsValue({
          ...s3Values,
          ...defaultValues,
        });
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
        notification.error({
          message: "Error loading inventory data",
          description: "Please try refreshing the page",
        });
      },
    });
  }, [form]);

  const clear = () => {
    setFileList([]);
    setEnabledSubmit(true);
    setWaiting(false);
    handleCancel();
  };

  /* Default Values */
  const s3Values = {
    customer: "Synthpop DME",
    patient_first_name: "Taylor",
    patient_last_name: "Swift",
    patient_id: "ABC31415926",
    patient_dob: dayjs("01-01-1990"),
    patient_email: "",
    patient_street_address: "123 Main St",
    patient_street_address2: "",
    patient_city: "Anytown",
    patient_state: "USA",
    patient_zip: "12345",
    caregivers_authorized: true,
    patient_phone: "",
    expert_number: "",
    questions_to_ask_inpatient_care: true,
    questions_to_ask_wear_and_tear: true,
    questions_to_ask_insurance_change: true,
    questions_to_ask_usage: true,
    questions_to_ask_last_seen_physician: true,
    questions_to_ask_office_pickup: true,
    questions_to_ask_outside_supplies: true,
    questions_to_ask_quantity_on_hand: true,
    physician_name: "Doogie Howser",
    physician_address_street_address: "1600 Pennsylvania Ave NW",
    physician_address_street_address2: "",
    physician_address_city: "Washington",
    physician_address_state: "DC",
    physician_address_zip: "20500",
    physician_phone: "",
    physician_fax: "",
    physician_npi: "",
    insurance_insurance_company_name: "Blue Cross Blue Shield",
    insurance_policy_number: "",
    insurance_group_number: "",
    insurance_policy_start_date: "",
    insurance_policy_end_date: "",
    insurance_guarantor_relation: "",
    insurance_primary_order: "1",
    equipment_scp_eligible: true,
    equipment_scp_supply_period: "90 days",
    equipment_mask_eligible: true,
    equipment_mask_location: "",
    equipment_mask_size: "",
    equipment_disposable_filter_eligible: true,
    equipment_disposable_filter_quantity: "3",
    equipment_disposable_filter_supply_period: "90 days",
    objectives: "NA",
    secondary_insurance_insurance_company_name: "",
    secondary_insurance_policy_number: "",
    secondary_insurance_group_number: "",
    secondary_insurance_policy_start_date: "",
    secondary_insurance_policy_end_date: "",
    secondary_insurance_guarantor_relation: "",
    secondary_insurance_primary_order: "2",
  };

  const validateEquipment = (equipment) => {
    if (!equipment.some((item) => item.eligible)) {
      return false;
    }

    return equipment.every((item) => {
      if (!item.eligible) return true;

      const isMaskType =
        item.product_type === "Mask" ||
        item.product_type === "Mask with Headgear";

      if (isMaskType) {
        return item.product && !["Machine", "Misc"].includes(item.product_type);
      }

      return (
        item.product &&
        !["Machine", "Misc"].includes(item.product_type) &&
        item.quantity &&
        item.supply_period
      );
    });
  };

  const handleSubmit = async (values) => {
    setWaiting(true);

    const equipment = productTypes
      .map((type) => {
        const baseKey = `equipment_${type === "Seals" ? "scp" : type.toLowerCase().replace(/ /g, "_")}`;
        const displayType = type === "Seals" ? "Seals/Cushions/Pillows" : type;
        return {
          product_type: displayType,
          eligible: values[`${baseKey}_eligible`] || false,
          product: values[`${baseKey}_product`],
          quantity: values[`${baseKey}_quantity`],
          supply_period: values[`${baseKey}_supply_period`],
          ...(type === "Mask" && {
            location: values[`${baseKey}_location`],
            size: values[`${baseKey}_size`],
          }),
        };
      })
      .filter((item) => item.eligible);

    if (!validateEquipment(equipment)) {
      notification.error({
        message:
          "Please select at least one eligible item and fill in all required fields",
      });
      setWaiting(false);
      return;
    }

    /* JSON Payload */
    let data_items = [];
    data_items = [
      {
        name: "Task Requirements",
        tag: "request-spec",
        data_type: "json",
        data: {
          customer: fetchDictValues(values, "customer"),
          patient_id: fetchDictValues(values, "patient_id"),
          patient_first_name: fetchDictValues(values, "patient_first_name"),
          patient_last_name: fetchDictValues(values, "patient_last_name"),
          patient_dob: fetchDictValues(values, "patient_dob").format(
            "MM-DD-YYYY"
          ),
          physician: {
            name: fetchDictValues(values, "physician_name"),
            phone: fetchDictValues(values, "physician_phone"),
            fax: fetchDictValues(values, "physician_fax"),
            street_address: fetchDictValues(
              values,
              "physician_address_street_address"
            ),
            city: fetchDictValues(values, "physician_address_city"),
            state: fetchDictValues(values, "physician_address_state"),
            zip: fetchDictValues(values, "physician_address_zip"),
          },
          questions_to_ask: {
            inpatient_care: fetchDictValues(
              values,
              "questions_to_ask_inpatient_care"
            ),
            wear_and_tear: fetchDictValues(
              values,
              "questions_to_ask_wear_and_tear"
            ),
            insurance_change: fetchDictValues(
              values,
              "questions_to_ask_insurance_change"
            ),
            usage: fetchDictValues(values, "questions_to_ask_usage"),
            last_seen_physician: fetchDictValues(
              values,
              "questions_to_ask_last_seen_physician"
            ),
            office_pickup: fetchDictValues(
              values,
              "questions_to_ask_office_pickup"
            ),
            quantity_on_hand: fetchDictValues(
              values,
              "questions_to_ask_quantity_on_hand"
            ),
            outside_supplies: fetchDictValues(
              values,
              "questions_to_ask_outside_supplies"
            ),
          },
          equipment: equipment,
          insurances: [
            {
              insurance_company_name: fetchDictValues(
                values,
                "insurance_insurance_company_name"
              ),
              policy_number: fetchDictValues(values, "insurance_policy_number"),
              group_number: fetchDictValues(values, "insurance_group_number"),
              policy_start_date: null,
              policy_end_date: null,
              guarantor_relation: fetchDictValues(
                values,
                "insurance_guarantor_relation"
              ),
              primary_order: fetchDictValues(values, "insurance_primary_order"),
            },
            {
              insurance_company_name: fetchDictValues(
                values,
                "secondary_insurance_insurance_company_name"
              ),
              policy_number: fetchDictValues(
                values,
                "secondary_insurance_policy_number"
              ),
              group_number: fetchDictValues(
                values,
                "secondary_insurance_group_number"
              ),
              policy_start_date: null,
              policy_end_date: null,
              guarantor_relation: fetchDictValues(
                values,
                "secondary_insurance_guarantor_relation"
              ),
              primary_order: fetchDictValues(
                values,
                "secondary_insurance_primary_order"
              ),
            },
          ],
          contact: {
            email: fetchDictValues(values, "patient_email"),
            phone: fetchDictValues(values, "patient_phone"),
            street_address: fetchDictValues(values, "patient_street_address"),
            street_address2: fetchDictValues(values, "patient_street_address2"),
            city: fetchDictValues(values, "patient_city"),
            state: fetchDictValues(values, "patient_state"),
            zip: fetchDictValues(values, "patient_zip"),
          },
          task_custom_fields: {
            expert_number: fetchDictValues(values, "expert_number"),
            objectives: fetchDictValues(values, "objectives"),
          },
          from_front_end: true,
        },
      },
    ];
    fileList.forEach((file, index) => {
      data_items.push({
        tag: "upload",
        data_type: "file",
        data: index,
        file_name: file.originFileObj.name,
      });
    });

    let createTask = {
      task_type: "voice_agent",
      data_items: data_items,
    };

    let formData = new FormData();
    formData.append("create_task", JSON.stringify(createTask));
    console.log(fileList);
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("uploads", file.originFileObj);
      });
    }
    AuthenticatedApi.post("/api/v2/task/create", formData)
      .then((response) => {
        if (response.data) {
          setTasks([response.data, ...tasks]);
        }
        clear();
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
        clear();
      });
  };

  const fetchDictValues = (values, key) => {
    return values[key] !== undefined ? values[key] : s3Values[key];
  };

  const EquipmentSection = ({ form }) => {
    const [showHeadgear, setShowHeadgear] = useState(false);

    const eligibilityStatus = productTypes.reduce((acc, type) => {
      const baseKey = `equipment_${type === "Seals" ? "scp" : type.toLowerCase().replace(/ /g, "_")}`;
      // ignore react-hooks/rules-of-hooks
      // eslint-disable-next-line react-hooks/rules-of-hooks
      acc[baseKey] = Form.useWatch(`${baseKey}_eligible`, form) || false; // Default to false if undefined
      return acc;
    }, {});
    return (
      <div className="form-container">
        {productTypes.map((type) => {
          const baseKey = `equipment_${type === "Seals" ? "scp" : type.toLowerCase().replace(/ /g, "_")}`;
          const isMaskType = type === "Mask";
          const displayType =
            type === "Seals" ? "Seals/Cushions/Pillows" : type;
          // Get the eligibility status from the precomputed object
          const isEligible = eligibilityStatus[baseKey];

          return (
            <div className="form-row equipment" key={type}>
              <div className="form-column equipment">
                <label>{displayType}</label>
              </div>
              <div className="form-column checkbox">
                <Form.Item name={`${baseKey}_eligible`} valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </div>
              <div className="form-column dropdown">
                <Form.Item
                  name={`${baseKey}_product`}
                  rules={[
                    {
                      required: isEligible,
                      message: `Please select a ${type}`,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={`Select ${type}`}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {inventoryByType[type]
                      ?.filter(
                        (item) =>
                          !isMaskType ||
                          (showHeadgear ? item.hasHeadgear : !item.hasHeadgear)
                      )
                      .map((item) => (
                        <Select.Option
                          key={item.productId}
                          value={item.productId}
                        >
                          {item.displayName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              {isMaskType ? (
                <div className="form-column input">
                  <Form.Item>
                    <Checkbox
                      onChange={(e) => setShowHeadgear(e.target.checked)}
                    >
                      Includes headgear
                    </Checkbox>
                  </Form.Item>
                </div>
              ) : (
                <>
                  <div className="form-column input">
                    <Form.Item
                      name={`${baseKey}_quantity`}
                      rules={[
                        {
                          required: isEligible,
                          message: "Please enter quantity",
                        },
                      ]}
                      initialValue={type === "Seals" ? "6" : undefined}
                    >
                      <Input placeholder="Quantity" />
                    </Form.Item>
                  </div>
                  <div className="form-column input">
                    <Form.Item
                      name={`${baseKey}_supply_period`}
                      rules={[
                        {
                          required: isEligible,
                          message: "Please enter supply period",
                        },
                      ]}
                      initialValue={type === "Seals" ? "90 days" : undefined}
                    >
                      <Input placeholder="Supply Period" />
                    </Form.Item>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      <Title className="gpt-form-title">AI Caller</Title>
      <p>Verify patient benefits by phone referencing their medical records.</p>
      <Form
        form={form}
        className="gpt-form-text"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={s3Values}
      >
        <h2>Customer Information</h2>
        <div className="form-row">
          <Form.Item
            name="customer"
            label="Customer"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>
        <h2>Patient Information</h2>
        <div className="form-row">
          <Form.Item
            name="patient_first_name"
            label="First Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="patient_last_name"
            label="Last Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="patient_dob"
            label="Date of Birth"
            rules={[{ required: true }]}
          >
            <DatePicker
              format="MM-DD-YYYY"
              disabledDate={(current) =>
                disableOutOfRangeDates(current, minDOB, maxDOB)
              }
            />
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item
            name="patient_id"
            label="Patient ID"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="patient_email"
            label="Email"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="patient_phone"
            label="Phone"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>

        <div className="form-row">
          <Form.Item
            name="patient_street_address"
            label="Street Address"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="patient_street_address2" label="Street Address 2">
            <Input />
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item
            name="patient_city"
            label="City"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="patient_state"
            label="State"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="patient_zip"
            label="ZIP Code"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>

        <h2>Insurance</h2>
        <h3>Primary Insurance</h3>
        <div className="form-row">
          <Form.Item
            name="insurance_insurance_company_name"
            label="Insurance Company Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="insurance_policy_number" label="Policy Number">
            <Input />
          </Form.Item>
          <Form.Item name="insurance_group_number" label="Group Number">
            <Input />
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item name="insurance_policy_start_date" label="Start Date">
            <DatePicker
              format="MM-DD-YYYY"
              disabledDate={(current) =>
                disableOutOfRangeDates(
                  current,
                  minInsuranceDate,
                  maxInsuranceDate
                )
              }
            />
          </Form.Item>
          <Form.Item name="insurance_policy_end_date" label="End Date">
            <DatePicker
              format="MM-DD-YYYY"
              disabledDate={(current) =>
                disableOutOfRangeDates(
                  current,
                  minInsuranceDate,
                  maxInsuranceDate
                )
              }
            />
          </Form.Item>
          <Form.Item
            name="insurance_guarantor_relation"
            label="Guarantor Relation"
          >
            <Input />
          </Form.Item>
        </div>

        <h3>Secondary Insurance</h3>
        <div className="form-row">
          <Form.Item
            name="secondary_insurance_insurance_company_name"
            label="Insurance Company Name"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="secondary_insurance_policy_number"
            label="Policy Number"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="secondary_insurance_group_number"
            label="Group Number"
          >
            <Input />
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item
            name="secondary_insurance_policy_start_date"
            label="Start Date"
          >
            <DatePicker
              format="MM-DD-YYYY"
              disabledDate={(current) =>
                disableOutOfRangeDates(
                  current,
                  minInsuranceDate,
                  maxInsuranceDate
                )
              }
            />
          </Form.Item>
          <Form.Item
            name="secondary_insurance_policy_end_date"
            label="End Date"
          >
            <DatePicker
              format="MM-DD-YYYY"
              disabledDate={(current) =>
                disableOutOfRangeDates(
                  current,
                  minInsuranceDate,
                  maxInsuranceDate
                )
              }
            />
          </Form.Item>
          <Form.Item
            name="secondary_insurance_guarantor_relation"
            label="Guarantor Relation"
          >
            <Input />
          </Form.Item>
        </div>

        <h2>Physician Information</h2>
        <div className="form-row">
          <Form.Item
            name="physician_name"
            label="Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="physician_phone" label="Phone">
            <Input />
          </Form.Item>
          <Form.Item name="physician_fax" label="Fax">
            <Input />
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item
            name="physician_address_street_address"
            label="Street Address"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="physician_address_city"
            label="City"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="physician_address_state"
            label="State"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="physician_address_zip"
            label="Zip"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>

        <h2>Equipment Eligibility and Quantity</h2>
        <EquipmentSection form={form} />

        <h2>Questions to Ask</h2>
        <div className="form-row">
          <Form.Item
            name="questions_to_ask_inpatient_care"
            label="Inpatient Care"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="questions_to_ask_wear_and_tear"
            label="Wear and Tear"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="questions_to_ask_insurance_change"
            label="Insurance Change"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="questions_to_ask_usage"
            label="Usage"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="questions_to_ask_last_seen_physician"
            label="Last Seen Physician"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="questions_to_ask_office_pickup"
            label="Office Pickup"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="questions_to_ask_outside_supplies"
            label="Outside Supplies"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="questions_to_ask_quantity_on_hand"
            label="Quantity on Hand"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </div>

        <h2>Expert Information</h2>
        <div className="form-row">
          <Form.Item name="expert_number" label="Contact number">
            <Input />
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={waiting}
            disabled={!enabledSubmit}
          >
            Place Call
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default S3TaskVoiceAgent;
