import React, { useState, useContext } from "react";
import { Tabs, Spin, Typography, notification, Timeline, Button } from "antd";
import TimeAgo from "react-timeago";
import CopyIconToClipboard from "./CopyIconToClipboard";
import InteractiveJsonViewer from "./InteractiveJsonViewer";
import DocumentTable from "./DocumentTable";
import TaskSteps from "./TaskSteps";
import "./OutputModal.css";
import { useTheme } from "../context/ThemeContext";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../utils/utilities";
import ConfigContext from "../context/ConfigContext";
import PDFViewer from "./PDFViewer";
import { Table } from "antd";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { DownloadOutlined } from "@ant-design/icons";

const { Title } = Typography;
const TaskDetails = (props) => {
  const { isDarkMode } = useTheme();
  const [downloadingItem, setDownloadingItem] = useState(-1);
  const [visibleItemId, setVisibleItemId] = useState(null);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const activeKey = props.activeKey;
  const setActiveKey = props.setActiveKey;
  const faxNumbers = props.faxNumbers || null;
  const selectedData = props.selectedData;
  const role = props.role;
  const unsavedChanges = props.unsavedChanges;
  const setHasUnsavedChanges = props.setHasUnsavedChanges;
  const removeUnsavedChange = props.removeUnsavedChange;
  const refreshResubmittedTask = props.refreshResubmittedTask;
  const setOpenedPdf = props.setOpenedPdf || (() => {});
  const config = useContext(ConfigContext);
  const pageCategories = config.page_categories;
  const [loadingMap, setLoadingMap] = useState({});
  const [audioSource, setAudioSource] = useState(null);

  const generatedLetterBackgroundStyle = {
    backgroundColor: isDarkMode ? "#1f1f1f" : "#e3e3e3",
  };

  const handleTabChange = (key) => {
    setAudioSource(null);
    setActiveKey(key); // Update the active tab key when a tab is clicked
  };

  const handleViewClick = async (id) => {
    setAudioSource(null);
    if (downloadingItem === id) {
      // View for this item is currently ongoing. Ignore further clicks until we are done.
      return;
    }
    if (visibleItemId === id) {
      setVisibleItemId(null);
      setOpenedPdf(false);
      return;
    }

    setDownloadingItem(id);

    AuthenticatedApi.get(`/api/task_metadata/${id}/download`)
      .then((response) => {
        console.log("Fetching URL for view: ", id, " is ", response.data.url);
        setVisibleItemId(id);
        setOpenedPdf(true);
        setSelectedFileUrl(response.data.url);
        setDownloadingItem(-1);
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
        setDownloadingItem(-1);
        setVisibleItemId(null); // Optionally hide the viewer on error
      });
  };

  const handleIntermediateZipDownload = async (rowId, taskId) => {
    setLoadingMap((prevMap) => ({
      ...prevMap,
      [rowId]: true,
    }));

    try {
      const zip = new JSZip();

      // Simulate fetching the intermediate files (You can replace this with actual API calls)
      const filesToDownload = [
        {
          name: "llm_logs.json",
          content: JSON.stringify(selectedData.llm_logs, null, 2),
        },
        {
          name: "eligibility_check_responses.json",
          content: JSON.stringify(
            selectedData.metadata.eligibility_check_responses,
            null,
            2
          ),
        },
        // Add other intermediate data similarly
      ];

      // Add files to the ZIP archive
      filesToDownload.forEach((file) => {
        zip.file(file.name, file.content);
      });

      // Fetch any additional file URLs from an API if needed (replace this with actual API calls)
      const additionalFiles = selectedData.structuredData
        ? selectedData.structuredData.map((data) => ({
            name: `${data.name}.json`,
            content: JSON.stringify(data.data, null, 2),
          }))
        : [];

      additionalFiles.forEach((file) => {
        zip.file(file.name, file.content);
      });

      // Generate the ZIP and prompt the download
      const blob = await zip.generateAsync({ type: "blob" });
      saveAs(blob, `intermediate_files_${taskId}.zip`);

      notification.success({
        message: "ZIP file downloaded successfully",
      });
    } catch (error) {
      console.error("Error generating ZIP:", error);
      const errorMessage =
        error.response?.data?.message || "Unknown error during ZIP download";
      notification.error({
        message: `Failed to download ZIP: ${errorMessage}`,
      });
    } finally {
      setLoadingMap((prevMap) => ({
        ...prevMap,
        [rowId]: false,
      }));
    }
  };

  const handleMetadataFileDownload = async (event, id) => {
    event.preventDefault();

    if (downloadingItem === id) {
      // Download for this item is currently ongoing. Ignore further
      // clicks until we are done.
      return;
    }

    setDownloadingItem(id);

    AuthenticatedApi.get(`/api/task_metadata/${id}/download`)
      .then((response) => {
        console.log("Download for ", id, " is ", response.data.url);
        fetch(response.data.url)
          .then((response) => response.blob())
          .then((blob) => {
            const blobUrl = window.URL.createObjectURL(blob);
            const tempLink = document.createElement("a");
            tempLink.href = blobUrl;
            tempLink.download = response.data.file_name;
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobUrl);
            setDownloadingItem(-1);
          });
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        setDownloadingItem(-1);
        notification.error({ message: errorMessage });
      });
  };

  const handlePlayAudio = async (event, id) => {
    event.preventDefault();

    if (downloadingItem === id) {
      // Download for this item is currently ongoing. Ignore further
      // clicks until we are done.
      return;
    }

    setDownloadingItem(id);

    AuthenticatedApi.get(`/api/task_metadata/${id}/download`)
      .then((response) => {
        console.log("Download for ", id, " is ", response.data.url);
        fetch(response.data.url)
          .then((response) => response.blob())
          .then(() => {
            if (response.data.file_name.endsWith(".mp3")) {
              setAudioSource(response.data.url);
            }
            setDownloadingItem(-1);
          });
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        setAudioSource(false);
        notification.error({ message: errorMessage });
      });
  };

  const handleMetadataFileFax = async (event, id) => {
    event.preventDefault();
    AuthenticatedApi.get(`/api/task_metadata/${id}/send_as_fax`)
      .then(() => {
        notification.success({
          message: formatMessage(config.messages.task.fax_sent_success),
        });
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
      });
  };

  const UploadedFile = ({
    data,
    downloadingItem,
    handleMetadataFileDownload,
    UploadStyle,
    customText,
    skipClass = false,
    showView = false,
  }) => {
    return (
      <div className={skipClass ? "" : "generated-letter"} style={UploadStyle}>
        <Title level={4}>{customText}</Title>
        <ol>
          {data.map((file) => (
            <li key={file.id}>
              {downloadingItem === file.id && <Spin />}
              <a
                href={`record/${file.id}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => handleMetadataFileDownload(e, file.id)}
              >
                {file.name}
              </a>
              {showView && (
                <button
                  style={{ marginLeft: "10px" }}
                  onClick={() => handleViewClick(file.id)}
                >
                  {visibleItemId === file.id ? "Hide" : "View"}
                </button>
              )}
              {showView && visibleItemId === file.id && selectedFileUrl && (
                <PDFViewer
                  fileUrl={selectedFileUrl}
                  pageCategories={pageCategories}
                  fileName={file.name}
                  pagesResults={selectedData.pagesResults}
                  taskId={selectedData.selectedRow.id}
                />
              )}
            </li>
          ))}
        </ol>
      </div>
    );
  };

  const fileData = selectedData.additionalDataInput.filter(
    (item) => item.type_ === "file"
  );

  const relevant_details = selectedData.additionalDataInput.filter(
    (item) => item.tag === "relevant-details" || item.tag === "issues-input"
  );

  const nonFileOrRelevantDetails = selectedData.additionalDataInput.filter(
    (item) =>
      item.type_ !== "file" &&
      item.tag !== "relevant-details" &&
      item.tag !== "issues-input"
  );

  const HcpcsTable = ({ selectedData, index }) => {
    const isOld =
      selectedData?.metadata?.hcpcs_verdict &&
      Object.keys(selectedData.metadata.hcpcs_verdict).length > 0;

    console.log("isOld", isOld);
    const dataSource = isOld
      ? selectedData?.metadata?.hcpcs_verdict
      : selectedData?.verdicts;

    if (
      !dataSource ||
      (!selectedData?.selectedRow?.task_type?.includes("pwc_") &&
        !selectedData?.selectedRow?.task_type?.includes("mwc_")) ||
      Object.keys(dataSource).length === 0 ||
      index !== 0
    ) {
      return null;
    }

    const getVerdictColor = (verdict) => {
      return isOld
        ? {
            met: "#D4EED2",
            unmet: "#F4D1D1",
            unchecked: "#E0E0E0",
            excluded: "#D8BFD8",
            "": "#F8D4B0",
          }[verdict] || "transparent"
        : verdict
          ? "#D4EED2"
          : "#F4D1D1"; // Simple met/unmet logic for new table
    };

    return (
      <Table
        style={{
          width: "100%",
          margin: "10px auto",
          fontSize: "12px",
        }}
        columns={[
          {
            title: "HCPCS Code",
            dataIndex: "hcpcs_code",
            key: "hcpcs_code",
            onHeaderCell: () => ({
              style: { padding: "4px" },
            }),
          },
          {
            title: "Verdict",
            dataIndex: "verdict",
            key: "verdict",
            render: (verdict) => {
              const displayVerdict = isOld
                ? verdict
                  ? verdict.charAt(0).toUpperCase() + verdict.slice(1)
                  : ""
                : verdict
                  ? "Met"
                  : "Unmet";

              return (
                <div
                  style={{
                    backgroundColor: getVerdictColor(verdict),
                    padding: "4px",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  {displayVerdict}
                </div>
              );
            },
          },
        ]}
        dataSource={Object.entries(dataSource).map(
          ([hcpcs_code, hcpcsEntry]) => ({
            hcpcs_code: isOld ? hcpcs_code : hcpcsEntry.code,
            verdict: isOld ? hcpcsEntry.verdict : hcpcsEntry.approved,
          })
        )}
        pagination={false}
        bordered
        rowKey="hcpcs_code"
        rowClassName={() => "small-row"}
      />
    );
  };

  return (
    <>
      {selectedData && selectedData.selectedRow && (
        <Tabs
          activeKey={activeKey}
          onChange={handleTabChange}
          items={[
            {
              key: "3",
              label: "Overview",
              children: (
                <div style={{ width: "100% !important" }}>
                  {fileData.length > 0 && (
                    <UploadedFile
                      data={fileData}
                      downloadingItem={downloadingItem}
                      handleMetadataFileDownload={handleMetadataFileDownload}
                      UploadStyle={{}}
                      customText="Uploaded Files"
                      skipClass={true}
                      showView={true}
                    />
                  )}

                  <TaskSteps
                    key={selectedData.selectedRow.id}
                    stepsStatus={selectedData.selectedRow}
                    taskId={selectedData.selectedRow.id}
                    refreshResubmittedTask={refreshResubmittedTask}
                    metadata={selectedData.metadata}
                    fileData={fileData}
                    pagesResults={selectedData.pagesResults}
                    structuredData={selectedData.structuredData}
                    verdicts={selectedData.verdicts}
                  />
                </div>
              ),
            },
            {
              key: "2",
              label: "Input",
              children: (
                <div>
                  {fileData.length > 0 && (
                    <UploadedFile
                      data={fileData}
                      downloadingItem={downloadingItem}
                      handleMetadataFileDownload={handleMetadataFileDownload}
                      UploadStyle={{}}
                      customText="Uploaded Files"
                      skipClass={true}
                      showView={true}
                    />
                  )}
                  {relevant_details.length > 0 && (
                    <div style={generatedLetterBackgroundStyle}>
                      <div style={{ padding: "20px", paddingBottom: "0px" }}>
                        <Title level={4}>Relevant Details</Title>
                      </div>
                      {relevant_details.map((data) => (
                        <CopyIconToClipboard
                          key={`${selectedData.selectedRow.id}-${selectedData.selectedRow.task_type}-input-${data.id}`}
                          fieldType={data.type_}
                          details={
                            data.type_ === "json"
                              ? JSON.stringify(data.data)
                              : data.data
                          }
                          unsavedChanges={unsavedChanges}
                          setHasUnsavedChanges={setHasUnsavedChanges}
                          removeUnsavedChange={removeUnsavedChange}
                          isEditable={false}
                        />
                      ))}
                    </div>
                  )}
                  {nonFileOrRelevantDetails.length > 0 &&
                    nonFileOrRelevantDetails.map((data, index) => (
                      <React.Fragment key={index}>
                        {data.type_ === "file" ? (
                          <></>
                        ) : (
                          <CopyIconToClipboard
                            key={`${selectedData.selectedRow.id}-${selectedData.selectedRow.task_type}-input-${data.id}`}
                            title={data.name}
                            fieldType={data.type_}
                            details={
                              data.type_ === "json"
                                ? JSON.stringify(data.data)
                                : data.data
                            }
                            unsavedChanges={unsavedChanges}
                            setHasUnsavedChanges={setHasUnsavedChanges}
                            removeUnsavedChange={removeUnsavedChange}
                            isEditable={false}
                          />
                        )}
                      </React.Fragment>
                    ))}
                </div>
              ),
            },
            role &&
              role === "superadmin" && {
                key: "4",
                label: "Intermediate",
                children: (
                  <>
                    <div>
                      {fileData.length > 0 && (
                        <UploadedFile
                          data={fileData}
                          downloadingItem={downloadingItem}
                          handleMetadataFileDownload={
                            handleMetadataFileDownload
                          }
                          UploadStyle={{}}
                          customText="Uploaded Files"
                          skipClass={true}
                          showView={true}
                        />
                      )}
                    </div>
                    {selectedData && selectedData.uuid && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Title level={4}>Task ID: {selectedData.uuid}</Title>
                        <Button
                          icon={<DownloadOutlined />} // Use the download icon
                          onClick={() =>
                            handleIntermediateZipDownload(
                              selectedData.selectedRow.id,
                              selectedData.uuid
                            )
                          }
                          style={{ marginLeft: "10px" }} // Add some spacing between the Task ID and button
                          loading={loadingMap[selectedData.selectedRow.id]}
                          disabled={loadingMap[selectedData.selectedRow.id]}
                          type="primary" // You can also add a type for the button if necessary
                          shape="circle" // Make the button circular to match the icon style
                        />
                      </div>
                    )}

                    <Tabs
                      type="card"
                      items={[
                        selectedData?.llm_logs && {
                          key: "llm_logs",
                          label: "LLM Logs",
                          children: (
                            <div
                              className="generated-letter"
                              style={generatedLetterBackgroundStyle}
                              key={`llm-logs-${selectedData?.selectedRow?.id}-`}
                            >
                              <InteractiveJsonViewer
                                title="LLM Logs"
                                details={JSON.stringify(
                                  selectedData.llm_logs,
                                  null,
                                  2
                                )}
                                fieldType="json" // or 'text' based on the content type
                                isEditable={false} // or true if you want the JSON to be editable
                                taskId={selectedData.selectedRow.id}
                                data_id={selectedData.selectedRow.id}
                                setHasUnsavedChanges={setHasUnsavedChanges}
                                removeUnsavedChange={removeUnsavedChange}
                              />
                            </div>
                          ),
                        },
                        selectedData?.selectedRow &&
                          selectedData?.documentAnalysisPrompts && {
                            key: "documentAnalysisPrompts",
                            label: "Document Analysis Prompts",
                            children: (
                              <div
                                className="generated-letter"
                                style={generatedLetterBackgroundStyle}
                              >
                                {selectedData?.documentAnalysisPrompts.map(
                                  (dap, index) => (
                                    <div
                                      key={`dap-${selectedData?.selectedRow?.id}-${dap.prompt_short_name}-${index}-`}
                                      style={{
                                        height: "600px",
                                        overflow: "auto",
                                      }}
                                    >
                                      <InteractiveJsonViewer
                                        title="Document Analysis Prompts"
                                        details={dap.raw_prompt}
                                        fieldType="json" // or 'text' based on the content type
                                        isEditable={false} // or true if you want the JSON to be editable
                                        taskId={selectedData.selectedRow.id}
                                        data_id={selectedData.selectedRow.id}
                                        setHasUnsavedChanges={
                                          setHasUnsavedChanges
                                        }
                                        removeUnsavedChange={
                                          removeUnsavedChange
                                        }
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            ),
                          },
                        selectedData?.metadata?.eligibility_check_responses && {
                          key: "eligibility_check_responses",
                          label: "Eligibility Check",
                          children: (
                            <div
                              className="generated-letter"
                              style={generatedLetterBackgroundStyle}
                            >
                              <InteractiveJsonViewer
                                key={`${selectedData.selectedRow.id}-${selectedData.selectedRow.task_type}-eligibility_check_responses`}
                                title="Eligiblity Check"
                                fieldType="json"
                                details={JSON.stringify(
                                  selectedData.metadata
                                    .eligibility_check_responses,
                                  null,
                                  2
                                )}
                                unsavedChanges={unsavedChanges}
                                setHasUnsavedChanges={setHasUnsavedChanges}
                                removeUnsavedChange={removeUnsavedChange}
                                isEditable={false}
                              />
                            </div>
                          ),
                        },
                        ...(selectedData?.structuredData
                          ? selectedData.structuredData.map((data, index) => {
                              return {
                                key: index.toString(),
                                label: data.name,
                                children:
                                  data.type_ === "file" ? (
                                    <div
                                      className="generated-letter"
                                      style={generatedLetterBackgroundStyle}
                                    >
                                      <Title level={3}>
                                        File Uploaded By User{" "}
                                        {downloadingItem === data.id && (
                                          <Spin />
                                        )}{" "}
                                      </Title>
                                      <a
                                        href={`record/${data.id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={(e) =>
                                          handleMetadataFileDownload(e, data.id)
                                        }
                                      >
                                        {data.name}
                                      </a>
                                    </div>
                                  ) : (
                                    <InteractiveJsonViewer
                                      key={`${selectedData.selectedRow.id}-${selectedData.selectedRow.task_type}-input-${data.id}-${selectedData?.documentCategory?.data}`}
                                      title={data.name}
                                      fieldType={data.type_}
                                      details={
                                        data.type_ === "json"
                                          ? JSON.stringify(data.data, null, 2)
                                          : data.data
                                      }
                                      unsavedChanges={unsavedChanges}
                                      setHasUnsavedChanges={
                                        setHasUnsavedChanges
                                      }
                                      removeUnsavedChange={removeUnsavedChange}
                                      isEditable={false}
                                    />
                                  ),
                              };
                            })
                          : []),
                      ].filter(Boolean)}
                    />
                  </>
                ),
              },
            {
              key: "1",
              label: "Output",
              children: (
                <div>
                  {/* Existing Output content */}
                  {selectedData.additionalData &&
                    selectedData.additionalData.map((data, index) => (
                      <React.Fragment key={index}>
                        {data.type_ === "file" ? (
                          <div
                            className="generated-letter"
                            style={{
                              ...generatedLetterBackgroundStyle,
                              padding: "20px",
                              margin: "10px 0",
                              border: "1px solid #ddd",
                              borderRadius: "8px",
                            }}
                          >
                            {/* Title with a spinner if downloading */}
                            <Title level={3} style={{ marginBottom: "20px" }}>
                              {data.name}{" "}
                              {downloadingItem === data.id && <Spin />}
                            </Title>

                            {/* File download link */}
                            <a
                              href={`record/${data.id}`}
                              target="_blank"
                              rel="noreferrer"
                              onClick={(e) =>
                                handleMetadataFileDownload(e, data.id)
                              }
                              style={{ display: "block", marginBottom: "10px" }}
                            >
                              {data.name}
                            </a>

                            {/* Fax option */}
                            {faxNumbers &&
                              faxNumbers.length > 0 &&
                              data.file_url &&
                              !data.file_url.includes(".mp3") && (
                                <a
                                  href={`record/${data.id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    display: "inline-block",
                                    backgroundColor: "#1890ff",
                                    marginBottom: "20px",
                                    padding: "8px 15px",
                                    color: "#fff",
                                    textDecoration: "none",
                                    borderRadius: "5px",
                                  }}
                                  onClick={(e) =>
                                    handleMetadataFileFax(e, data.id)
                                  }
                                >
                                  Send as Fax
                                </a>
                              )}

                            {/* Play Audio Button */}
                            {data.name.endsWith(".mp3") && (
                              <button
                                onClick={(e) => handlePlayAudio(e, data.id)}
                                style={{
                                  marginRight: "10px",
                                  padding: "10px 20px",
                                  backgroundColor: "#4CAF50",
                                  color: "#fff",
                                  border: "none",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  marginBottom: "10px",
                                }}
                              >
                                Play Audio
                              </button>
                            )}

                            {/* Download Button */}
                            <button
                              onClick={(e) =>
                                handleMetadataFileDownload(e, data.id)
                              }
                              style={{
                                padding: "10px 20px",
                                backgroundColor: "#007BFF",
                                color: "#fff",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                marginBottom: "20px",
                              }}
                            >
                              Download
                            </button>

                            {/* Conditional rendering for MP3 */}
                            {audioSource && (
                              <div style={{ marginTop: "20px" }}>
                                {/* Audio player */}
                                <audio
                                  controls
                                  autoPlay={true}
                                  style={{ width: "100%" }}
                                  onEnded={() => setAudioSource(null)} // Reset audioSource when audio ends
                                >
                                  <source src={audioSource} type="audio/mpeg" />
                                  Your browser does not support the audio
                                  element.
                                </audio>
                              </div>
                            )}
                          </div>
                        ) : (
                          <>
                            <CopyIconToClipboard
                              key={`${selectedData.selectedRow.task_id}-${selectedData.selectedRow.task_type}-assessment-${data.id}`}
                              title={data.name}
                              taskId={selectedData.selectedRow.id}
                              fieldType={data.type_}
                              details={
                                data.type_ === "json"
                                  ? JSON.stringify(data.data, null, 2)
                                  : data.data
                              }
                              data_id={data.id}
                              isEditable={true}
                              unsavedChanges={unsavedChanges}
                              setHasUnsavedChanges={setHasUnsavedChanges}
                              removeUnsavedChange={removeUnsavedChange}
                              tableElement={
                                <HcpcsTable
                                  selectedData={selectedData}
                                  index={index}
                                />
                              }
                              oldTableElement={
                                selectedData?.metadata?.hcpcs_verdict &&
                                Object.keys(selectedData.metadata.hcpcs_verdict)
                                  .length > 0
                              }
                            />
                          </>
                        )}
                      </React.Fragment>
                    ))}
                  {/* Documents section */}
                  {selectedData.metadata &&
                    selectedData.metadata.identified_documents &&
                    selectedData.metadata.identified_documents.length > 0 &&
                    selectedData.selectedRow.status === "Completed" && (
                      <div
                        className="generated-letter"
                        style={generatedLetterBackgroundStyle}
                      >
                        <Title level={3}>Documents</Title>
                        <DocumentTable
                          dataSource={
                            selectedData.metadata.identified_documents
                          }
                          fileData={fileData}
                        />
                      </div>
                    )}
                  {/* Event Timelines */}
                  {selectedData.event_logs && (
                    <div
                      className="generated-letter"
                      style={generatedLetterBackgroundStyle}
                    >
                      <Title level={3}>Event Timelines</Title>
                      <Timeline>
                        {selectedData.event_logs.map((log, index) => (
                          <Timeline.Item key={index}>
                            {log.user_name} {log.event} on{" "}
                            {new Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              timeZoneName: "short",
                            }).format(new Date(log.timestamp))}{" "}
                            (
                            <span style={{ fontSize: "smaller" }}>
                              <TimeAgo date={log.timestamp} />
                            </span>
                            )
                          </Timeline.Item>
                        ))}
                      </Timeline>
                    </div>
                  )}
                </div>
              ),
            },
          ].filter(Boolean)}
        />
      )}
    </>
  );
};

export default TaskDetails;
